import Header from '@/components/header';
import { Card } from '@/components/ui/card.tsx';
import { BiChat, BiEnvelope } from 'react-icons/bi';
import ResponsiveTabs from '@/routes/help/responsive-tabs.tsx';
import useIntercom from '@/hooks/use-intercom.tsx';
import { useAuth } from '@/providers/auth.tsx';
import { Button } from '@/components/ui/button.tsx';

const HelpPage = () => {
  const { user } = useAuth();
  useIntercom(user);
  return (
    <div className="bg-app-background">
      <Header />
      <div className="px-4 sm:px-0 max-w-[800px] mx-auto mt-20 pb-20">
        <div className="flex flex-col gap-y-6">
          <h1 className="text-3xl font-semibold">
            Let's Get You the Help You Need
          </h1>
          <div>
            <h2 className="text-xl font-semibold">
              Frequently Asked Questions
            </h2>
            <p>
              Looking for quick answers? We've gathered information about
              getting started, using Peopled, privacy & security, technical
              support, and more
            </p>
          </div>
          <Card>
            <ResponsiveTabs />
          </Card>
          <h2 className="text-xl font-semibold">
            Still can’t find what you’re looking for?
          </h2>
          <div className="flex flex-col gap-y-5 md:flex-row md:gap-x-5">
            <Card className="p-6 flex-1">
              <BiChat className="text-4xl mb-2" />
              <p className="font-bold mb-4">Chat with Us</p>
              <p className=" mb-4">
                Find answers and explore solutions together
              </p>
              <Button
                variant="link"
                className="p-0 underline"
                onClick={() => {
                  if (window.Intercom) {
                    window.Intercom('show');
                  }
                }}
              >
                Start help chat
              </Button>
            </Card>
            <Card className="p-6 flex-1">
              <BiEnvelope className="text-4xl mb-2" />
              <p className="font-bold mb-6">Email Support</p>
              <p className=" mb-4">Send us a note at care@peopled.com</p>
              <p className="text-sm">
                We typically respond within 1 business day.
              </p>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HelpPage;
