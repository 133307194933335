import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/components/ui/accordion.tsx';

const HelpTechnicalSupport = () => {
  return (
    <Accordion type="single" collapsible className="w-full">
      <AccordionItem value="item-1">
        <AccordionTrigger>What browsers are supported?</AccordionTrigger>
        <AccordionContent>
          Peopled works on all major modern browsers, including Chrome, Safari,
          Firefox, and Edge.
        </AccordionContent>
      </AccordionItem>
      <AccordionItem value="item-2">
        <AccordionTrigger>
          What if I&apos;m having technical difficulties?
        </AccordionTrigger>
        <AccordionContent>
          <p className="mb-4">
            Try refreshing your browser first. Then, see if logging out and
            logging back in helps.
          </p>
          <p>
            If issues persist, contact our technical support team through the
            support chat under Help or at care@peopled.com.
          </p>
        </AccordionContent>
      </AccordionItem>
      <AccordionItem value="item-3">
        <AccordionTrigger>Is there a mobile app?</AccordionTrigger>
        <AccordionContent>
          At this point, no, but that’s a good idea...
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  );
};

export default HelpTechnicalSupport;
