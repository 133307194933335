import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/components/ui/accordion.tsx';

const HelpPrivacySecurity = () => {
  return (
    <Accordion type="single" collapsible className="w-full">
      <AccordionItem value="item-1">
        <AccordionTrigger>Is my information secure?</AccordionTrigger>
        <AccordionContent>
          Yes. Your personal data is stored securely by Peopled and is never
          shared with the underlying AI engine. Peopled helps you understand
          your benefits by accessing your company&apos;s general benefits
          documentation and policies.
        </AccordionContent>
      </AccordionItem>
      <AccordionItem value="item-2">
        <AccordionTrigger>
          Who can see my conversations with the AI?
        </AccordionTrigger>
        <AccordionContent>
          Your conversations are private. No other employees or managers can see
          your chat history. Only you can see your conversations with Peopled.
        </AccordionContent>
      </AccordionItem>
      <AccordionItem value="item-3">
        <AccordionTrigger>
          What information does Peopled have access to?
        </AccordionTrigger>
        <AccordionContent>
          <div className="flex flex-col gap-y-4">
            <div>
              <p>Peopled has access to:</p>
              <ul className="list-disc pl-3">
                <li>Your company’s benefits plans and policies</li>
                <li>General financial and retirement planning information</li>
                <li>Company programs and resources</li>
                <li>Public financial education resources</li>
              </ul>
            </div>
            <p>
              When you ask questions, it provides guidance based on this
              knowledge while you maintain control over what personal
              information you choose to share in the conversation.
            </p>
            <p>
              The underlying AI platform never sees your personal data, account
              information, or private details. This information is securely
              stored by Peopled. You may view the information Peopled has in
              your account profile.
            </p>
          </div>
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  );
};

export default HelpPrivacySecurity;
