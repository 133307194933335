import ChatResponse from '@/components/chat/chat-response.tsx';
import { Button } from '@/components/ui/button.tsx';
import { useOnboardingStore } from '@/routes/onboarding/store.ts';
import { sdk } from '@/api.ts';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '@/providers/auth.tsx';

const ErrorsAdviceNotice = () => {
  const navigate = useNavigate();
  const { preferredName, financialAwarenessId } = useOnboardingStore();
  const { refreshUser } = useAuth();

  const message = `And finally, think of me as a knowledgeable friend who helps you understand your benefits. I explain things clearly, but don’t give specific advice. It is always good to discuss and verify important details with a financial planner or advisor.\n\nReady to get started?`;

  const handleClick = async () => {
    const updatedUserObject = {
      preferred_name: preferredName,
      onboarding_complete: true,
      user_detail: {
        financial_awareness_id: financialAwarenessId,
      },
    };
    try {
      await sdk.updateUser(updatedUserObject);
      await refreshUser();
      navigate('/app');
    } catch (error) {
      console.error('Error updating user:', error);
    }
  };
  return (
    <div>
      <ChatResponse message={message} type="bot" />
      <div className="pl-14 max-w-[320px]">
        <Button onClick={handleClick}>Yes, let's get started.</Button>
      </div>
    </div>
  );
};

export default ErrorsAdviceNotice;
