import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/components/ui/accordion.tsx';

const HelpGettingStarted = () => {
  return (
    <Accordion type="single" collapsible className="w-full">
      <AccordionItem value="item-1">
        <AccordionTrigger> What is Peopled?</AccordionTrigger>
        <AccordionContent>
          Peopled is your personal guide to understanding and making the most of
          your benefits. It helps you navigate your options, understand your
          coverage, and make informed decisions about your financial future.
        </AccordionContent>
      </AccordionItem>
      <AccordionItem value="item-2">
        <AccordionTrigger>How do I access Peopled?</AccordionTrigger>
        <AccordionContent>
          Log in to your Peopled account from a web browser, following
          instructions for entering the one-time passcode. You can access
          Peopled on your phone, tablet, or computer.
        </AccordionContent>
      </AccordionItem>
      <AccordionItem value="item-3">
        <AccordionTrigger>Is this replacing human support?</AccordionTrigger>
        <AccordionContent>
          No. Peopled complements our human support team. For complex issues or
          when you prefer to speak with a person, your benefits specialists are
          still available to help.
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  );
};

export default HelpGettingStarted;
