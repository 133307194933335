import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/components/ui/accordion.tsx';

const HelpUsingPeopled = () => {
  return (
    <Accordion type="single" collapsible className="w-full">
      <AccordionItem value="item-1">
        <AccordionTrigger>What kinds of questions can I ask?</AccordionTrigger>
        <AccordionContent>
          <p>You can ask about:</p>
          <ul className="list-disc pl-3">
            <li>Your benefits coverage and options</li>
            <li>Financial planning and retirement</li>
            <li>Healthcare choices</li>
            <li>Savings and investment strategies</li>
            <li>Company policies and programs</li>
            <li>Deadlines and important dates</li>
          </ul>
        </AccordionContent>
      </AccordionItem>
      <AccordionItem value="item-2">
        <AccordionTrigger>How can I get the best results?</AccordionTrigger>
        <AccordionContent>
          Be specific with your questions and provide context about your
          situation. The more relevant information you share, the more
          personalized the guidance will be.
        </AccordionContent>
      </AccordionItem>
      <AccordionItem value="item-3">
        <AccordionTrigger>
          What&apos;s the best way to use AI assistant?
        </AccordionTrigger>
        <AccordionContent>
          Type &apos;help&apos; in the chat to see examples of effective
          questions and get tips for better responses.
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  );
};

export default HelpUsingPeopled;
