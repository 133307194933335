import OrganizationDetailsCard from '@/routes/dashboard/organization/organization-details-card.tsx';
import { useEffect, useState } from 'react';
import { sdk } from '@/api.ts';
import { useAuth } from '@/providers/auth.tsx';

const Organization = () => {
  const { user } = useAuth();

  const [organization, setOrganization] = useState(null);
  const [loading, setLoading] = useState(true);

  const fetchOrganization = async () => {
    const orgResult = await sdk.getOrganization(user.organization_id);
    setOrganization(orgResult.data);
    setLoading(false);
  };

  useEffect(() => {
    setLoading(true);
    fetchOrganization();
  }, []);

  if (loading || !organization) {
    return <div>Loading...</div>;
  }
  return (
    <div>
      <div className="max-w-[60%]">
        <h1 className="text-2xl font-semibold mb-4">Company Information</h1>
        <OrganizationDetailsCard
          onUpdate={fetchOrganization}
          organization={organization}
        />
      </div>
    </div>
  );
};

export default Organization;
