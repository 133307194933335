import { useState } from 'react';
import { Tabs, TabsList, TabsTrigger, TabsContent } from '@/components/ui/tabs';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import HelpGettingStarted from '@/routes/help/getting-started.tsx';
import HelpPrivacySecurity from '@/routes/help/privacy-security.tsx';
import HelpUsingPeopled from '@/routes/help/using-peopled.tsx';
import HelpTechnicalSupport from '@/routes/help/technical-support.tsx';

const ResponsiveTabs = () => {
  const [activeTab, setActiveTab] = useState('getting-started');

  const tabItems = [
    {
      value: 'getting-started',
      label: 'Getting Started',
      content: <HelpGettingStarted />,
    },
    {
      value: 'privacy-security',
      label: 'Privacy & Security',
      content: <HelpPrivacySecurity />,
    },
    {
      value: 'using-peopled',
      label: 'Using Peopled',
      content: <HelpUsingPeopled />,
    },
    {
      value: 'technical-support',
      label: 'Technical Support',
      content: <HelpTechnicalSupport />,
    },
  ];

  const handleValueChange = (value: string) => {
    setActiveTab(value);
  };

  return (
    <div>
      {/* Tabs for desktop */}
      <div className="hidden md:block">
        <Tabs value={activeTab} onValueChange={handleValueChange}>
          <TabsList className="grid grid-cols-4 w-full">
            {tabItems.map((tab) => (
              <TabsTrigger key={tab.value} value={tab.value}>
                {tab.label}
              </TabsTrigger>
            ))}
          </TabsList>
          {tabItems.map((tab) => (
            <TabsContent key={tab.value} value={tab.value}>
              <div className="p-4 mt-2">{tab.content}</div>
            </TabsContent>
          ))}
        </Tabs>
      </div>

      {/* Dropdown for mobile */}
      <div className="block md:hidden">
        <Select value={activeTab} onValueChange={handleValueChange}>
          <SelectTrigger className="w-full">
            <SelectValue placeholder="Select a tab" />
          </SelectTrigger>
          <SelectContent>
            {tabItems.map((tab) => (
              <SelectItem key={tab.value} value={tab.value}>
                {tab.label}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
        <div className="mt-4">
          {tabItems.map(
            (tab) =>
              tab.value === activeTab && (
                <div key={tab.value} className="p-4">
                  {tab.content}
                </div>
              )
          )}
        </div>
      </div>
    </div>
  );
};

export default ResponsiveTabs;
