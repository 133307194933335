import { Card } from '@/components/ui/card.tsx';
import { Badge } from '@/components/ui/badge.tsx';
import { Link } from 'react-router-dom';
import { BiChevronRight, BiUserVoice } from 'react-icons/bi';

interface OnboardingDashboardProps {
  employeeCount?: number;
}

const pluralize = (count?: number) => {
  if (!count) {
    count = 0;
  }
  const plural = count > 1 || count === 0;
  return plural ? `${count} people` : `${count} person`;
};

const OnboardingDashboard = ({ employeeCount }: OnboardingDashboardProps) => {
  return (
    <div className="flex flex-col gap-y-4 max-w-[745px] w-full">
      <Card className="p-6">
        <div className="flex gap-x-4">
          <h2 className="text-2xl font-semibold">Onboarding Steps</h2>
          <Badge variant="pending">Setup in progress</Badge>
        </div>
        <div className="p-4 bg-muted rounded my-4">
          <p className="text-sm">
            Before your employees can access Peopled, there are a couple
            required steps to setup and customize your Peopled experience.
          </p>
        </div>
        {/* ----------------------------- */}
        <div>
          <p className="font-semibold">Enter Company Info</p>
          <div className="flex justify-between">
            <div>
              <p>
                Make sure your company name, address and primary contact info is
                up to date.
              </p>
            </div>
            <div className="flex ml-8">
              <Link
                to={'/dashboard/organization'}
                className="underline whitespace-nowrap"
              >
                Company Info
              </Link>
              <BiChevronRight size={24} />
            </div>
          </div>
        </div>
        <hr className="my-4" />
        {/* ----------------------------- */}
        <div>
          <div className="flex gap-x-4">
            <p className="font-semibold">Add Employees</p>
            <Badge variant="success">{pluralize(employeeCount)} added</Badge>
          </div>
          <div className="flex justify-between">
            <div>
              <p>
                Tell us about your employees. Email Invites will automatically
                be sent to employees once the setup has been completed.
              </p>
            </div>
            <div className="flex ml-8">
              <Link
                to={'/dashboard/employees'}
                className="underline whitespace-nowrap"
              >
                Employees
              </Link>
              <BiChevronRight size={24} />
            </div>
          </div>
        </div>
        <hr className="my-4" />
        {/* ----------------------------- */}
        <div>
          <p className="font-semibold">Send Benefit Info</p>
          <div className="flex justify-between">
            <div>
              <p>
                Email your benefit documents to us. These documents will
                customize the experience to the specific benefits you offer.
              </p>
            </div>
            <div className="flex ml-8">
              <a
                href="mailto:care@trypeopled.com"
                className="underline whitespace-nowrap"
              >
                care@trypeopled.com
              </a>
            </div>
          </div>
        </div>
        {/* ----------------------------- */}
      </Card>
      <Card className="p-6">
        <div>
          <BiUserVoice size={34} />
          <p className="font-semibold">Collaborate With Team</p>
          <div className="flex justify-between">
            <div>
              <p>
                Invite additional admins to help manage your Peopled experience.
              </p>
            </div>
            <div className="flex ml-8">
              <Link
                to={'/dashboard/administrators'}
                className="underline whitespace-nowrap"
              >
                Admins
              </Link>
              <BiChevronRight size={24} />
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default OnboardingDashboard;
