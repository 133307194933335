import Header from '@/components/header';
import { useAuth } from '@/providers/auth.tsx';
import { ReactNode } from 'react';
import { BiChat, BiChevronRight, BiEnvelope, BiUser } from 'react-icons/bi';
import { Link, Outlet, useLocation } from 'react-router-dom';

interface ILinkCardProps {
  icon: ReactNode;
  title: string;
  description: string;
  path: string;
}

const LinkCard = ({ icon, title, description, path }: ILinkCardProps) => {
  return (
    <Link to={path}>
      <div className="flex bg-white p-2 items-center border rounded">
        <div className="px-4">{icon}</div>
        <div className="flex-1">
          <p className="text-sm font-bold">{title}</p>
          <p className="text-sm ">{description}</p>
        </div>
        <div className="px-4">
          <BiChevronRight size={24} />
        </div>
      </div>
    </Link>
  );
};

export default function Account() {
  const { user, loading } = useAuth();
  const location = useLocation();
  const isBaseRoute = location.pathname === '/app/account';

  if (loading) {
    return <div>Loading....</div>;
  }

  return (
    <div className="bg-app-background h-full overflow-auto">
      <Header />
      <div className="max-w-[640px] mx-auto px-4">
        {isBaseRoute && (
          <>
            <div className="my-2">
              <h2 className="font-bold">Account</h2>
              <p>
                for {user.first_name} {user.last_name}
              </p>
            </div>
            <div className="flex flex-col gap-y-2">
              <LinkCard
                icon={<BiUser size={24} />}
                title="Personal Information"
                description="Personal and Financial Details"
                path="/app/account/personal"
              />
              <LinkCard
                icon={<BiEnvelope size={24} />}
                title="Contact Information"
                description="Email, mailing address, and phone"
                path="/app/account/contact"
              />
              <LinkCard
                icon={<BiChat size={24} />}
                title="Chat History"
                description="View, download, or delete chat history"
                path="/app/account/chat-history"
              />
            </div>
          </>
        )}
        <Outlet />
      </div>
    </div>
  );
}
