import ChatSuggestions from '@/components/chat/chat-suggestions.tsx';
import { AnimatePresence, motion } from 'framer-motion';
import { IMessage } from '@/components/chat/types.ts';

interface ChatEmptyStateProps {
  handleClickSuggestion: (text: string) => void;
  messages: IMessage[];
}

const ChatEmptyState = ({
  handleClickSuggestion,
  messages,
}: ChatEmptyStateProps) => {
  const fadeOut = !!messages.length;
  return (
    <AnimatePresence>
      <motion.div
        initial={{ opacity: 1 }}
        animate={{
          opacity: fadeOut ? 0 : 1,
          display: fadeOut ? 'none' : 'block',
          visibility: fadeOut ? 'hidden' : 'visible',
        }}
        transition={{ duration: 0.5, delay: 0 }}
        exit={{ opacity: 0 }}
      >
        <ChatSuggestions onClickSuggestion={handleClickSuggestion} />
      </motion.div>
    </AnimatePresence>
  );
};

export default ChatEmptyState;
