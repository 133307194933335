import { create } from 'zustand';
import { sdk } from '@/api.ts';
import { IMessage } from '@/components/chat/types.ts';
import { readStream, sendChatMessage } from '@/components/chat/utils.ts';
import { useAppStore } from '@/routes/store.tsx';

interface IChatStore {
  messages: IMessage[];
  loading: boolean;
  shouldAutoScroll: boolean;
  showScrollButton: boolean;
  showRetry: boolean;
  userMessageIsAtTop: boolean;
  error: string | null;
  addUserMessage: (message: string) => void;
  fetchMessages: () => void;
  update: (obj: Partial<IChatStore>) => void; // Function for combined updates
}

export const useChatStore = create<IChatStore>((set) => ({
  messages: [],
  loading: false,
  shouldAutoScroll: true,
  showScrollButton: false,
  showRetry: false,
  userMessageIsAtTop: false,
  error: null,
  update: (stateObject: Partial<IChatStore>) => {
    set({
      ...stateObject,
    });
  },
  addUserMessage: async (text: string) => {
    // set state to loading, and add user message to state
    set((state: IChatStore) => {
      return {
        loading: true,
        shouldAutoScroll: true,
        userMessageIsAtTop: false,
        showRetry: false,
        messages: [
          ...state.messages,
          {
            message_content: text,
            created_at: new Date().toISOString(),
            user_generated_message: true,
          },
          {
            message_content: '',
            created_at: new Date().toISOString(),
            user_generated_message: false,
          },
        ],
      };
    });

    try {
      // Creating a callback here to throw the "logged out" modal if the user is not authenticated
      const failureCallback = useAppStore.getState().setShowLoggedOutModal;
      const reader = await sendChatMessage(text, () => failureCallback(true));
      const decoder = new TextDecoder('utf-8');
      // Create a new bot message object and set it in state to hold the incoming chunks
      set((state: IChatStore) => {
        const newMessages = [...state.messages];
        // Replacing last message (bot message with '' as content) with new message for chunking
        newMessages[newMessages.length - 1] = {
          message_content: '',
          created_at: new Date().toISOString(),
          user_generated_message: false,
        };

        return {
          messages: [...newMessages],
        };
      });

      await readStream(reader!, decoder, (botMessage) => {
        const showRetry =
          botMessage === 'I hit a technical snag. Could you try that again?';
        // Update the last bot message with each new chunk
        set((state: IChatStore) => {
          const newMessages = [...state.messages];
          newMessages[newMessages.length - 1].message_content = botMessage;
          return { messages: newMessages, showRetry };
        });
      });
      set({ loading: false });
    } catch (error) {
      set({ error: 'Error sending message', loading: false });
    }
  },
  fetchMessages: async () => {
    set({ loading: true });
    try {
      const response = await sdk.getChatThreads();
      set({ messages: response.thread_history, loading: false });
    } catch (error) {
      set({ error: 'Error retrieving messages', loading: false });
    }
  },
}));
