import ChatResponse from '@/components/chat/chat-response.tsx';
import { Button } from '@/components/ui/button.tsx';
import { useOnboardingStore } from '@/routes/onboarding/store.ts';

const TermsAcknowledgement = () => {
  const { setStepIndex } = useOnboardingStore();
  const message = `A few things you should know before we start working together. 
  <p><b>Peopled is committed to keeping you and your data safe.</b><br>Your data is securely stored separately from the AI engine, is not shared with others (unless you say so) and will not be sold to third-parties. You can learn more about Peopled’s <span class="text-success"> <a href="https://www.peopled.com/privacy-policy-1" target="_blank">Privacy Policy.</a></span></p> 
`;

  const handleClickAcknowledgeTerms = () => {
    setStepIndex(3);
  };

  return (
    <div>
      <ChatResponse message={message} type="bot" />
      <div className="pl-14 mt-4">
        <Button onClick={handleClickAcknowledgeTerms}>
          Got It, Let’s Continue
        </Button>
      </div>
    </div>
  );
};

export default TermsAcknowledgement;
