import ChatInput from '@/components/chat/chat-input.tsx';
import { useEffect, useRef } from 'react';
import { useChatStore } from '@/components/chat/store.tsx';
import ChatFeed from '@/components/chat/chat-feed.tsx';
import ChatEmptyState from '@/components/chat/chat-empty-state.tsx';
import { Button } from '@/components/ui/button.tsx';
import { BiDownArrowAlt, BiRefresh } from 'react-icons/bi';

const Chat = () => {
  const {
    addUserMessage,
    fetchMessages,
    loading,
    messages,
    shouldAutoScroll,
    showScrollButton,
    showRetry,
    userMessageIsAtTop,
    update,
  } = useChatStore();
  const messagesEndRef = useRef<HTMLDivElement | null>(null);
  const lastUserMessageRef = useRef<HTMLDivElement | null>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);

  const scrollToBottom = (fromButton: boolean = false) => {
    if (containerRef.current && lastUserMessageRef.current) {
      const lastUserMessageOffset = lastUserMessageRef.current.offsetTop;
      if (lastUserMessageOffset > -40 && lastUserMessageOffset < 40) {
        update({
          shouldAutoScroll: false,
          showScrollButton: true,
          userMessageIsAtTop: true,
        });
        return lastUserMessageRef.current.scrollIntoView({
          behavior: 'smooth',
        });
      }
    }

    if (fromButton && messagesEndRef.current) {
      update({
        shouldAutoScroll: false,
      });
      return messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }

    if (shouldAutoScroll && messagesEndRef.current && !userMessageIsAtTop) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  // method for handling when a participant manually starts scrolling
  const handleScroll = () => {
    if (!containerRef.current) return;

    const container = containerRef.current;
    const isNearBottom = container.scrollTop >= -20;
    update({
      showScrollButton: !isNearBottom,
      shouldAutoScroll: isNearBottom,
      userMessageIsAtTop: false,
    });
  };

  useEffect(() => {
    fetchMessages();
  }, []);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const handleRetry = () => {
    const lastUserMessage = messages.findLast((m) => m.user_generated_message);
    handleSubmit(lastUserMessage!.message_content);
  };

  const handleSubmit = (text: string) => {
    const isEmpty = text.trim() === '';
    if (loading || isEmpty) return;

    try {
      addUserMessage(text);
    } catch (error) {
      console.log('error: ', error);
    }
  };

  return (
    <div className="flex flex-col h-[calc(100%)]">
      <div
        ref={containerRef}
        onScroll={handleScroll}
        className="flex-1 flex flex-col-reverse items-center overflow-y-auto"
      >
        <div test-id="chat-message-feed" className="w-full max-w-[640px]">
          {messages.length === 0 && !loading && (
            <ChatEmptyState
              handleClickSuggestion={(text) => handleSubmit(text)}
              messages={messages}
            />
          )}
          <ChatFeed
            lastUserMessageRef={lastUserMessageRef}
            loading={loading}
            messages={messages}
          />
          {showRetry && (
            <Button
              className="flex gap-x-1 items-center ml-16 mt-[-8px]"
              onClick={handleRetry}
            >
              Retry <BiRefresh size={20} />
            </Button>
          )}
          <div ref={messagesEndRef} />
        </div>
      </div>

      <div className="flex justify-center sticky bottom-0 w-full">
        <div className="max-w-[640px] w-full px-4 pb-4">
          <div className="py-4 flex justify-center">
            {showScrollButton ? (
              <Button
                variant="circle"
                size="icon"
                className="mx-auto"
                onClick={() => {
                  scrollToBottom(true);
                }}
              >
                <BiDownArrowAlt />
              </Button>
            ) : (
              <div className="w-10 h-10"></div>
            )}
          </div>
          <ChatInput onSubmit={handleSubmit} loading={loading} />
        </div>
      </div>
    </div>
  );
};

export default Chat;
