import { create } from 'zustand';

export interface AppStore {
  showLoggedOutModal: boolean;
  setShowLoggedOutModal: (show: boolean) => void;
}

export const useAppStore = create<AppStore>((set) => ({
  showLoggedOutModal: false,
  setShowLoggedOutModal: (show: boolean) => set({ showLoggedOutModal: show }),
}));
