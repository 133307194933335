import ChatSuggestion from '@/components/chat/chat-suggestion.tsx';
import DynamicFace from '@/components/dynamic-face.tsx';
const ChatSuggestions = ({
  onClickSuggestion,
}: {
  onClickSuggestion: (_text: string) => void;
}) => {
  return (
    <div test-id="chat-suggestions" className="mb-12">
      <div className="flex justify-center mb-2.5">
        <div className="w-[50px] h-[50px]">
          <DynamicFace />
        </div>
      </div>
      <h2 className="text-2xl text-center font-semibold mb-5">
        How can we help you today?
      </h2>
      <div className="flex gap-x-2">
        <div className="flex-1">
          <ChatSuggestion handleClick={onClickSuggestion} variant="book-search">
            What is my company's leave policy?
          </ChatSuggestion>
        </div>
        <div className="flex-1">
          <ChatSuggestion
            handleClick={onClickSuggestion}
            variant="investment_selection"
          >
            Tell me about my company's 401k.
          </ChatSuggestion>
        </div>
        <div className="flex-1 hidden md:block">
          <ChatSuggestion handleClick={onClickSuggestion} variant="medical">
            What prescriptions does my plan cover?
          </ChatSuggestion>
        </div>
      </div>
    </div>
  );
};

export default ChatSuggestions;
