import { useAuth } from '@/providers/auth.tsx';
import { Navigate } from 'react-router-dom';
import { ReactNode } from 'react';
import API from '@/api.ts';
import { useAppStore } from '@/routes/store.tsx';
import AuthTimeoutModal from '@/components/auth-timeout-modal.tsx';

export default function ProtectedRoute({ element }: { element: ReactNode }) {
  const { user, loading } = useAuth();
  const { showLoggedOutModal, setShowLoggedOutModal } = useAppStore();
  API.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        setShowLoggedOutModal(true);
      }
      return Promise.reject(error);
    }
  );

  if (loading) {
    return 'Loading...';
  }

  if (user) {
    return (
      <>
        <AuthTimeoutModal open={showLoggedOutModal} />
        {element}
      </>
    );
  }

  return <Navigate to="/login" />;
}
