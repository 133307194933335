import { format } from 'date-fns';

export function formatDateSeparatorString(date: Date) {
  const now = new Date();
  const isToday = now.toDateString() === date.toDateString();

  if (isToday) {
    return 'Today';
  }
  // date-fns 'do' returns the "ordinal time" (e.g. 1st, 2nd, 3rd, 4th, etc.)
  return format(date, 'MMMM do, yyyy');
}

export async function sendChatMessage(
  message: string,
  failureCallback: () => void
) {
  const baseURL = `${import.meta.env.VITE_API_BASE_URL}/api/v1`;

  try {
    //we are using fetch because axios doesn't support the streaming
    const url = baseURL + '/chat/messages';
    const headers = {
      'Content-Type': 'application/json',
      'X-CSRF-TOKEN': localStorage.getItem('csrf_access_token') || '',
    };
    const response = await fetch(url, {
      method: 'POST',
      headers: headers,
      credentials: 'include',
      body: JSON.stringify({ message }),
    });

    if (!response.ok) {
      if (response.status === 401) {
        failureCallback();
      }
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    if (!response.body) {
      throw new Error('ReadableStream not supported in this browser.');
    }

    return response.body.getReader();
  } catch (error) {
    console.error('Error sending message:', error);
  }
}

export const readStream = async (
  reader: ReadableStreamDefaultReader<Uint8Array>,
  decoder: TextDecoder,
  onChunk: (chunk: string) => void
) => {
  let botMessage = '';

  const readChunk = async () => {
    const { value, done } = await reader.read();
    if (done) return;

    const chunk = decoder.decode(value);
    botMessage += chunk;
    onChunk(botMessage);

    await readChunk();
  };

  await readChunk();
};
