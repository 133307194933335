import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogTitle,
} from '@/components/ui/dialog.tsx';
import { useAuth } from '@/providers/auth.tsx';
import { Button } from '@/components/ui/button.tsx';

interface AuthTimeoutModalProps {
  open: boolean;
}

const AuthTimeoutModal = ({ open = false }: AuthTimeoutModalProps) => {
  const { logout } = useAuth();

  const handleLogout = () => {
    logout();
  };
  return (
    <Dialog open={open}>
      <DialogContent className="[&>button:last-child]:hidden">
        <DialogTitle>Session Timeout</DialogTitle>
        <DialogDescription className="hidden">
          Your session has timed out. Please log in again.
        </DialogDescription>
        <div>
          <p>
            Looks like you’ve been away for a bit. For your security, we’ve
            logged you out. Please log back in to continue.
          </p>
          <div className="mt-4">
            <Button onClick={handleLogout}>Return to login</Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default AuthTimeoutModal;
