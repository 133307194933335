import { IUser } from '@/types.ts';
import { useEffect } from 'react';
import Intercom from '@intercom/messenger-js-sdk';

const useIntercom = (user: IUser) => {
  useEffect(() => {
    if (user) {
      const fullName = `${user.first_name} ${user.last_name}`;
      const createdAt =
        typeof user.created_at === 'string'
          ? Math.floor(new Date(user.created_at).getTime() / 1000)
          : user.created_at;

      Intercom({
        app_id: 's1par89m',
        user_id: user.id,
        name: fullName,
        email: user.email,
        created_at: createdAt,
      });
    }

    return () => {
      if (window.Intercom) {
        window.Intercom('shutdown');
      }
    };
  }, [user]);
};

export default useIntercom;
