import { ColumnDef } from '@tanstack/react-table';
import { IUser } from '@/types.ts';
import { format } from 'date-fns';
import { ArrowUpDown } from 'lucide-react';
import { Button } from '@/components/ui/button.tsx';
import { Badge } from '@/components/ui/badge.tsx';

export const columns: ColumnDef<IUser>[] = [
  {
    accessorKey: 'first_name',
    header: 'First Name',
  },
  {
    accessorKey: 'last_name',
    header: ({ column }) => {
      return (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
        >
          Last Name
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </Button>
      );
    },
  },
  {
    accessorKey: 'email',
    header: 'Email Address',
  },
  {
    accessorKey: 'hire_dt',
    header: 'Hire Date',
    cell: ({ row }) => {
      const date: string = row.getValue('hire_dt');
      if (date) {
        // Remove dashes from date string and replace with hyphens due to a
        // really fun bug in date-fns!
        // https://stackoverflow.com/a/69472603/1992958
        return format(new Date(date.replace(/-/g, '/')), 'MM/dd/yy');
      }
      return 'N/A';
    },
  },
  {
    accessorKey: 'termination_dt',
    header: 'Termination Date',
    cell: ({ row }) => {
      const date: string = row.getValue('termination_dt');
      if (date) {
        return format(new Date(date), 'MM/dd/yy');
      }
      return 'N/A';
    },
  },
  {
    accessorKey: 'status',
    header: 'Status',
    cell: (context) => {
      switch (context.getValue()) {
        case 'registered':
          return <Badge variant="success">Registered</Badge>;
        case 'pre-invitation':
          return <Badge variant="pending">Pending Invitation</Badge>;
        case 're-invited':
          return <Badge>Re-Invited</Badge>;
        case 'invited':
          return <Badge>Invited</Badge>;
        default:
          return '';
      }
    },
  },
];
