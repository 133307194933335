import { IMetrics } from '@/types.ts';
import { Card } from '@/components/ui/card.tsx';
import { ProgressBarChart } from '@/components/progress-bar-chart.tsx';
import { ChartContainer } from '@/components/ui/chart.tsx';
import { Pie, PieChart } from 'recharts';
import { BiTimeFive } from 'react-icons/bi';

interface ActiveDashboardProps {
  metrics?: IMetrics | null;
}

const metricsDisplayValues = (metrics: IMetrics) => {
  const { adoption_rate, total_employees, total_chat_users } = metrics;
  return {
    adoptionRate: adoption_rate ? `${adoption_rate.toFixed()}%` : '-',
    chatEngagement:
      total_employees && total_chat_users
        ? `${((total_chat_users / total_employees) * 100).toFixed(1)}%`
        : '-',
  };
};

const chartData = (metrics: IMetrics) => [
  {
    type: 'engaged',
    data: metrics.total_chat_users,
    fill: 'hsl(var(--purple))',
  },
  {
    type: 'unengaged',
    data: metrics.total_users - metrics.total_chat_users,
    fill: 'hsl(var(--neutral-200))',
  },
];

const ActiveDashboard = ({ metrics }: ActiveDashboardProps) => {
  if (!metrics) return <p>Loading...</p>;

  const displayMetrics = metricsDisplayValues(metrics);
  return (
    <div>
      <div className="flex gap-x-2.5">
        <Card className="flex-1 flex flex-col">
          <p className="text-xl font-semibold mb-4">Employee Registration</p>
          <p className="text-xl font-bold">{displayMetrics.adoptionRate}</p>
          <p className="font-medium text-sm text-muted-foreground">
            of employees created an account
          </p>
          <div className="my-4 flex flex-col flex-1 justify-center">
            <ProgressBarChart value={metrics.adoption_rate} />
            <p className="text-right text-xs text-muted-foreground mt-2">
              Total employees invited: {metrics.total_employees}
            </p>
          </div>
          <div id="bottom" className="flex gap-x-4 mt-auto">
            <div className="flex items-center gap-x-2">
              <div className="bg-purple w-4 h-4 rounded-full"></div>
              <div>{metrics.total_users} registered</div>
            </div>
            <div className="flex items-center gap-x-2">
              <div className="bg-neutral-200 w-4 h-4 rounded-full"></div>
              <div>{metrics.total_employees - metrics.total_users} pending</div>
            </div>
          </div>
        </Card>
        <Card className="flex-1">
          <p className="text-xl font-semibold mb-4">Chat Engagement</p>
          <p className="text-xl font-bold">{displayMetrics.chatEngagement}</p>
          <p className="font-medium text-sm text-muted-foreground">
            of registered employees have started a chat
          </p>
          <div>
            <ChartContainer
              config={{}}
              className="mx-auto aspect-square max-h-[135px]"
            >
              <PieChart>
                <Pie data={chartData(metrics)} dataKey="data" nameKey="type" />
              </PieChart>
            </ChartContainer>
          </div>
          <div className="flex gap-x-4">
            <div className="flex items-center gap-x-2">
              <div className="bg-purple w-4 h-4 rounded-full"></div>
              <div>{metrics.total_chat_users} engaged</div>
            </div>
            <div className="flex items-center gap-x-2">
              <div className="bg-neutral-200 w-4 h-4 rounded-full"></div>
              <div>
                {metrics.total_users - metrics.total_chat_users} unengaged
              </div>
            </div>
          </div>
        </Card>
        <Card className="flex flex-col flex-1">
          <p className="text-xl font-semibold mb-4">Employer Impact</p>
          <p className="font-medium text-sm text-muted-foreground">
            Time saved through chat support
          </p>
          <div className="flex flex-1 justify-center items-center gap-2">
            <BiTimeFive className="text-purple" size={48} />
            <div className="flex items-baseline">
              <p className="text-5xl font-medium mr-1">
                {metrics.employer_hours_saved || 0}
              </p>
              <p className="text-2xl text-muted-foreground">hours</p>
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
};

export default ActiveDashboard;
